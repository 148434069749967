import React from "react"

import clsx from "clsx"
import Image from "next/image"
import ShieldSVG from "public/assets/talkToAMentor/shield.svg"

const Confidential = ({ className }: { className?: string }) => (
  <div className={clsx("flex space-x-0.5", className)}>
    <Image alt="shield" src={ShieldSVG} />
    <span
      className="text-xs font-normal leading-[15.6px] text-left"
      style={{
        fontFamily: "Inter",
      }}
    >
      Confidential & Free of Cost
    </span>
  </div>
)

export default Confidential
